'use client';

import {
	AppRouterInstance,
	NavigateOptions,
} from 'next/dist/shared/lib/app-router-context.shared-runtime';
import {
	usePathname,
	// eslint-disable-next-line no-restricted-imports
	useRouter,
} from 'next/navigation';
import {
	TransitionStartFunction,
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
	useTransition,
} from 'react';
import { getIsNewWindowTargeted } from './link/functions/getIsNewWindowTargeted';
import { getHrefKind } from './link/functions/getHrefKind';
import { pushLinkEvent } from '../analytics/events/pushLinkEvent';
// eslint-disable-next-line no-restricted-imports
import { LinkProps } from 'next/link';

export type DOPRouter = AppRouterInstance & {
	onLinkClick: (
		event: React.MouseEvent<HTMLAnchorElement>,
		scroll?: LinkProps['scroll'],
	) => void;
	startTransition: TransitionStartFunction;
};

const DOPRouterContext = createContext<DOPRouter | undefined>(undefined);
const IsNavigatingContext = createContext<boolean | undefined>(undefined);
const LinksToSelfClickedCountContext = createContext<number | undefined>(
	undefined,
);

const getHashTarget = (hash: string) => {
	if (!hash) return undefined;

	const target = document.getElementById(hash.slice(1)) ?? document.body;

	return target;
};

const scrollToTarget = (target: HTMLElement) => {
	target.focus({ preventScroll: true });
	if (target === document.body) {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	} else {
		target.scrollIntoView({ behavior: 'smooth' });
	}
};

const getInternalSelfTarget = (
	href: string,
	options: NavigateOptions | undefined,
) => {
	const hrefKind = getHrefKind(href);

	if (!(options?.scroll ?? true)) return undefined;
	if (hrefKind !== 'INTERNAL_SELF') return undefined;

	const url = new URL(href, window.location.href);

	const target = getHashTarget(url.hash);

	return target;
};

export const DOPRouterProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [isNavigating, startTransition] = useTransition();
	const nextRouter = useRouter();
	const pathname = usePathname();
	const [linksToSelfClicked, setLinksToSelfClicked] = useState(0);

	useEffect(() => {
		setLinksToSelfClicked(0);
	}, [pathname]);

	const router: DOPRouter = useMemo(
		() => ({
			onLinkClick: (
				event: React.MouseEvent<HTMLAnchorElement>,
				scroll = true,
			) => {
				const link = event.currentTarget;
				const { href } = link;
				if (href === '') return;

				const isNewWindowTargeted = getIsNewWindowTargeted(event);
				const hrefKind = getHrefKind(href);

				if (!isNewWindowTargeted) {
					if (hrefKind === 'INTERNAL_SELF') {
						setLinksToSelfClicked((count) => count + 1);
					}

					if (hrefKind === 'INTERNAL' || hrefKind === 'INTERNAL_SELF') {
						event.preventDefault();
						router.push(href, { scroll });
					}
				}

				const dataset = { ...link.dataset };
				const linkType = link.getAttribute('data-link-type') || '';
				const text = link.innerText;

				pushLinkEvent({
					href,
					dataset,
					linkType,
					hrefKind,
					text,
				});
			},
			startTransition,
			push: (href, options) => {
				startTransition(() => {
					const internalSelfTarget = getInternalSelfTarget(href, options);
					if (internalSelfTarget) {
						scrollToTarget(internalSelfTarget);
						nextRouter.push(href, { ...options, scroll: false });
						return;
					}
					nextRouter.push(href, options);
				});
			},
			replace: (href, options) => {
				startTransition(() => {
					const internalSelfTarget = getInternalSelfTarget(href, options);
					if (internalSelfTarget) {
						scrollToTarget(internalSelfTarget);
						nextRouter.push(href, { ...options, scroll: false });
						return;
					}
					nextRouter.replace(href, options);
				});
			},
			refresh: () => {
				startTransition(() => {
					nextRouter.refresh();
				});
			},
			back: () => {
				startTransition(() => {
					nextRouter.back();
				});
			},
			forward: () => {
				startTransition(() => {
					nextRouter.forward();
				});
			},
			prefetch: nextRouter.prefetch,
		}),
		[nextRouter],
	);

	return (
		<DOPRouterContext.Provider value={router}>
			<IsNavigatingContext.Provider value={isNavigating}>
				<LinksToSelfClickedCountContext.Provider value={linksToSelfClicked}>
					{children}
				</LinksToSelfClickedCountContext.Provider>
			</IsNavigatingContext.Provider>
		</DOPRouterContext.Provider>
	);
};

export const useDOPRouter = () => {
	const context = useContext(DOPRouterContext);
	if (context === undefined) {
		throw new Error('useDOPRouter must be used within a DOPRouterProvider');
	}
	return context;
};

export const useIsNavigating = () => {
	const context = useContext(IsNavigatingContext);
	if (context === undefined) {
		throw new Error('useIsNavigating must be used within a DOPRouterProvider');
	}
	return context;
};

export const useLinksToSelfClicked = () => {
	const context = useContext(LinksToSelfClickedCountContext);
	if (context === undefined) {
		throw new Error(
			'useLinksToSelfClicked must be used within a DOPRouterProvider',
		);
	}
	return context;
};
