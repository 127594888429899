import { pushEvent } from '../pushEvent';
import { LinkAction } from './pushLinkEvent';

// Events & helpers for decisionTree (startupbox on BGOV)
const decisionTreeEventName = 'interaction.decisionTree';

export const pushDecisionTreeStartedEvent = () => {
	pushEvent({
		events: {
			category: decisionTreeEventName,
			action: 'start',
		},
	});
};

export const pushDecisionTreeQuestionAnsweredEvent = ({
	questionText,
	answerText,
}: {
	questionText: string;
	answerText: string;
}) => {
	pushEvent({
		events: {
			category: decisionTreeEventName,
			action: questionText,
			label: answerText,
		},
	});
};

export const pushDecisionTreeResultEvent = ({
	dataset,
}: Pick<LinkAction, 'dataset'>) => {
	const resultText = dataset?.question || '';

	pushEvent({
		events: {
			category: decisionTreeEventName,
			action: 'result',
			label: resultText,
		},
	});
};
