import { SearchState } from '@/globals/dataCollector/searchDataCollector/types/searchParams.types';
import { pushEvent } from '../pushEvent';
import { SearchHistory } from '@/sections/search/useSearchHistory';
import { getProjectFacets } from '@/globals/dataCollector/searchDataCollector/config/searchFacets.config';
import { PageHistory } from '@/globals/pageWrappers/usePageHistory';
import { LinkAction } from './pushLinkEvent';

export const getEventActionName = (searchState: SearchState) => {
	const facets = getProjectFacets();

	const currentFacetConfig = facets.find(
		({ slug }) => slug === searchState.facetSlug,
	);

	if (currentFacetConfig == null) return undefined;

	const { eventActionName } = currentFacetConfig;

	return eventActionName;
};

export const getSearchTermHistory = (
	searchHistory: SearchHistory,
	term?: string,
) => {
	const searchTermHistory: Array<string> = [];

	for (const { term } of searchHistory) {
		if (searchTermHistory.at(-1) !== term) {
			searchTermHistory.push(term);
		}
	}

	if (term != null) searchTermHistory.push(term);

	return searchTermHistory;
};

export const pushSearchResultEvent = ({
	searchState,
	searchHistory,
	pageHistory,
}: {
	searchState: SearchState;
	searchHistory: SearchHistory;
	pageHistory: PageHistory;
}) => {
	const eventActionName = getEventActionName(searchState);

	if (eventActionName == null) return;

	const term = searchState.term;

	const searchTermHistory = getSearchTermHistory(searchHistory, term);

	// Retrieve the last non-search pathname (or if not available the currentPath)
	const previousPath =
		pageHistory.findLast(({ providerType }) => providerType !== 'search')
			?.pathname || pageHistory.at(-1)?.pathname;

	pushEvent({
		events: {
			category: 'interaction.searchResult',
			action: eventActionName,
			label: term,
		},
		search: {
			numberOfSearches: searchTermHistory.length,
			searchRefinement: searchTermHistory.join(' > '),
			previousPath,
		},
	});
};

export const pushSearchLinkEvent = ({
	href,
	linkType,
	dataset,
}: LinkAction) => {
	const { facet, term, total, numberOfSearches, refinement } = dataset;

	pushEvent({
		events: {
			category: 'interaction.searchResultClick',
			action: linkType,
			label: href,
		},
		search: {
			searchFacet: facet,
			searchTerm: term,
			targetUrl: href,
			numberOfResults: total,
			numberOfSearches,
			searchRefinement: refinement,
		},
	});
};
