export type HrefKind =
	| 'EXTERNAL'
	| 'INTERNAL'
	| 'INTERNAL_STATIC_CONTENT'
	| 'INTERNAL_SELF'
	| 'NON_HTTP';

export type HrefLoadKind = HrefKind | 'PAGE_LOAD';

const staticContentPathnamePrefixes = [
	'/public/',
	'/includes/',
	'/_data/',
	'/sitemap.xml',
	'/sitemapnews.xml',
];

export const getHrefKind = (href: string): HrefKind => {
	const hrefURL = new URL(href, window.location.href);

	if (!['http:', 'https:'].includes(hrefURL.protocol)) {
		return 'NON_HTTP';
	}
	if (hrefURL.origin !== window.location.origin) {
		return 'EXTERNAL';
	}

	if (
		staticContentPathnamePrefixes.some((pathnamePrefix) =>
			hrefURL.pathname.startsWith(pathnamePrefix),
		)
	) {
		return 'INTERNAL_STATIC_CONTENT';
	}

	if (hrefURL.pathname === window.location.pathname) {
		return 'INTERNAL_SELF';
	}

	return 'INTERNAL';
};
