export const getIsNewWindowTargeted = (
	event: MouseEvent | React.MouseEvent,
) => {
	if (event.metaKey) return true;
	if (event.ctrlKey) return true;
	if (event.shiftKey) return true;
	// Not the primary mouse button
	if (event.button !== 0) return true;
	if (
		event.currentTarget instanceof HTMLAnchorElement &&
		event.currentTarget.target != null &&
		event.currentTarget.target !== '' &&
		event.currentTarget.target !== '_self'
	) {
		return true;
	}

	return false;
};
