import { HrefKind } from '@/globals/routing/link/functions/getHrefKind';
import { pushEvent } from '../pushEvent';
import { pushSearchLinkEvent } from './pushSearchEvent';
import { decisionTreeResultLinkType } from '../../../../projects/bgov/src/components/decisionTree/decisionTreeConfig';
import { pushDecisionTreeResultEvent } from './pushDecisionTreeEvent';

export type LinkAction = {
	href: string;
	hrefKind: HrefKind;
	linkType: string;
	dataset: Record<string, string | undefined>;
	text: string;
};

export const pushExternalLinkEvent = ({
	href,
	linkType,
}: Pick<LinkAction, 'href' | 'linkType'>) => {
	pushEvent({
		events: {
			category: 'interaction.externalLink',
			action: linkType,
			label: href,
		},
	});
};

export const pushSocialLinkEvent = ({
	href,
	dataset,
}: Pick<LinkAction, 'href' | 'dataset'>) => {
	pushEvent({
		events: {
			category: 'interaction.social',
			action: dataset.socialType,
			label: href,
		},
	});
};

export const pushStaticContentLinkEvent = ({
	href,
	linkType,
}: Pick<LinkAction, 'href' | 'linkType'>) => {
	pushEvent({
		events: {
			category: 'interaction.link',
			action: linkType,
			label: href,
		},
	});
};

export const pushNonHTTPLinkEvent = ({
	href,
	linkType,
}: Pick<LinkAction, 'href' | 'linkType'>) => {
	pushEvent({
		events: {
			category: 'interaction.link',
			action: linkType,
			label: href?.startsWith('blob:') ? 'blob:' : href,
		},
	});
};

export const pushAnchorLinkEvent = ({
	linkType,
	text,
}: Pick<LinkAction, 'text' | 'linkType'>) => {
	pushEvent({
		events: {
			category: 'interaction.anchorlink',
			action: linkType,
			label: text,
		},
	});
};

export const pushInternalLinkEvent = ({
	href,
	linkType,
}: Pick<LinkAction, 'href' | 'linkType'>) => {
	pushEvent({
		events: {
			category: 'interaction.link',
			action: linkType,
			label: href,
		},
	});
};

/**
 * If it is an internal link, strip the hostname
 */
const normalizeHREF = (href: string) => {
	const url = new URL(href, window.location.origin);

	if (url.origin !== window.location.origin) return href;

	return `${url.pathname}${url.search}${url.hash}`;
};

export const pushLinkEvent = (linkAction: LinkAction) => {
	const action = { ...linkAction, href: normalizeHREF(linkAction.href) };

	switch (action.hrefKind) {
		case 'EXTERNAL': {
			if (action.linkType === 'shareMenu') pushSocialLinkEvent(action);
			else pushExternalLinkEvent(action);
			break;
		}
		case 'INTERNAL_STATIC_CONTENT': {
			pushStaticContentLinkEvent(action);
			break;
		}
		case 'NON_HTTP': {
			pushNonHTTPLinkEvent(action);
			break;
		}
		case 'INTERNAL_SELF': {
			pushAnchorLinkEvent(action);
			break;
		}
		case 'INTERNAL': {
			if (action.linkType === 'searchResult') pushSearchLinkEvent(action);
			if (action.linkType === decisionTreeResultLinkType)
				pushDecisionTreeResultEvent(action);
			else pushInternalLinkEvent(action);
			break;
		}
	}
};
