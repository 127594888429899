import { classes } from '../base/classes.helpers';
import {
	textBlockBase,
	textBlockSprinkles,
	textStyleBase,
} from './textBlock.css';
import { textInlineBase, textInlineSprinkles } from './textInline.css';

export type TextBlockProps = Parameters<typeof textBlockSprinkles>[0];
export type TextStyleProps = TextBlockProps;
export type TextInlineProps = Parameters<typeof textInlineSprinkles>[0];

/**
 * Component for styling blocks of text
 *
 * Responsibilities:
 *
 * 1. Make text block start at cap height and end at base line.
 * 2. Text styling
 *
 * Note: every block of text should be wrapped in this component to make sure spacing before and after text fits snugly
 */
export const textBlock = (props: TextBlockProps) => {
	return classes(textBlockBase, textBlockSprinkles(props));
};

/**
 * TextStyle is used to style multiple descending `textBlock`’s.
 *
 * Note: the text block itself should still be wrapped inside a `textBlock`’s.
 * because the `TextBlock` corrects the spacing before and after the text
 * using `CapSize.js`.
 *
 * Responsibilities:
 *
 * 1. Styling of text for mulptiple text blocks
 * 2. Setting CSS variables for `capSize` and `capLineHeight`
 */
export const textStyle = (props: TextStyleProps) => {
	return classes(textStyleBase, textBlockSprinkles(props));
};

/**
 * Component for styling inline text
 *
 * Responsibilities:
 *
 * 1. Text styling for inline text
 *
 * Note: This does _not_ take care of fitting spacing snugly around text.
 * It should therefor be used as an descendant of `textBlock`
 */
export const textInline = (props: TextInlineProps) => {
	return classes(textInlineBase, textInlineSprinkles(props));
};
